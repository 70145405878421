import React, { useEffect, ReactElement } from 'react';
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Typography,
  CardHeader,
} from '@bb-ui/react-library';
import useRestApi from 'hooks/useRestApi';
import { UseRestApiResult } from '../../../hooks/useRestApi.types';
import { apiUrl } from 'utils/apiUrl';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { DdaInformationItem } from './DdaInformationItem';
import { DdaClientData } from 'App.types';

interface DdaInformationResp extends Omit<UseRestApiResult, 'data'> {
  data: DdaClientData;
}

export const useStyles = makeStyles(() =>
  createStyles({
    heading: {
      marginBottom: '14px',
    },
    section: {
      marginTop: '24px',
    },
  })
);

export const DdaInformation: React.FC = (props) => {
  const { tenantId } = useParams<{ tenantId: string }>();
  const { t } = useTranslation();
  const classes = useStyles(props);
  const { data, error, loading, fetch } = useRestApi(
    apiUrl('lct', `dda/${tenantId}/clients`)
  ) as DdaInformationResp;
  let content: ReactElement = <></>;

  useEffect(() => {
    fetch();
  }, [fetch]);

  const loader = () => (
    <CircularProgress
      ariaLabel={t('global.loadingIndicator.loading').toString()}
      data-testid="loading-dda-info"
    />
  );

  const erroMessage = () => (
    <Typography data-testid="dda-info-error">{t('global.fetchError')}</Typography>
  );

  const noDdaInfoMessage = () =>
    (content = (
      <Typography data-testid="dda-info-no-data">{t('ddaInformation.noData')}</Typography>
    ));

  const ddaInformation = () => {
    return (
      <div data-testid="dda-information">
        <DdaInformationItem title={t('ddaInformation.foundationsId')} value={data?.tenantId} />
        <DdaInformationItem title={t('ddaInformation.name')} value={data?.name} />
        <DdaInformationItem title={t('ddaInformation.ddaCname')} value={data?.ddaCname} />
        <DdaInformationItem title={t('ddaInformation.region')} value={data?.region} />
        <DdaInformationItem
          title={t('ddaInformation.isActive')}
          value={data?.isActive ? 'Yes' : 'No'}
        />
        <DdaInformationItem title={t('ddaInformation.tenantType')} value={undefined} />
      </div>
    );
  };

  if (error) {
    content = erroMessage();
  } else if (loading) {
    content = loader();
  } else if (!data || !Object.keys(data).length) {
    content = noDdaInfoMessage();
  } else {
    content = ddaInformation();
  }

  return (
    <div data-testid="dda-info-section">
      <CardHeader
        title={t('ddaInformation.pageTitle')}
        titleTypographyProps={{
          color: 'textSecondary',
          component: 'h3' as any,
          variant: 'h4',
        }}
        className={classes.heading}
      />
      {content}
    </div>
  );
};
